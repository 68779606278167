import { Component, Vue } from 'vue-property-decorator'
import { Employed } from '@/Models'
import http from '@/services'
import Search from '@/components/Search/index.vue'
import EmployedForm from '@/components/EmployedForm/EmployedForm.vue'
import ModalDelete from '@/components/ModalDelete/ModalDelete.vue'
import modalComponent from '@/components/ModalComponent/ModalComponent.vue'
import { email } from 'vee-validate/dist/rules'
import userModal from '@/components/UsersForm/UsersForm'
import authModule from '@/store/auth'
import FiltersButton from '@/components/FiltersButton/index.vue'
import menuModule from '@/store/menu'

@Component({
  components: {
    Search,
    EmployedForm,
    ModalDelete,
    modalComponent,
    FiltersButton,
  },
})
export default class EmployedList extends Vue {
  action = 'list'
  delete_modal_active = false
  objEdit: Employed = new Employed()
  headers: Array<any> = [
    {
      field: 'identity_card',
      label: this.$t('employes.identity_card'),
      width: '40',
    },
    {
      field: 'first_name',
      label: this.$t('employes.first_name'),
      width: '40',
    },
    {
      field: 'middle_name',
      label: this.$t('employes.middle_name'),
      width: '40',
    },
    {
      field: 'last_name',
      label: this.$t('employes.last_name'),
      width: '40',
    },
    {
      field: 'email',
      label: this.$t('employes.email'),
      width: '40',
    },
    {
      field: 'departmentName',
      label: this.$t('employes.department'),
      width: '40',
    },
    {
      field: 'userName',
      label: this.$t('employes.user'),
      width: '30',
    },
    {
      field: 'allowCMS',
      label: this.$t('employes.allowCMS'),
      width: '30',
    },
  ]
  data: Array<Employed> = []

  mounted() {
    this.getEmployeds()
  }

  filterItems(items: any) {
    this.data = items
  }

  add() {
    this.action = 'create'
  }

  edit(employed: Employed) {
    console.log(employed)
    this.objEdit = employed
    //this.objEdit.permissions = this.getAction(Department)
    this.action = 'edit'
  }

  async getEmployeds() {
    menuModule.setLoding(true)
    try {
      const res = await http.get('panel/employee/')
      if (!res.data) throw new Error(res.data)
      console.log(res)
      this.data = res.data.map((x: Employed) => ({
        ...x,
        identity_card: x.identity_card,
        first_name: x.first_name,
        middle_name: x.middle_name,
        last_name: x.last_name,
        email: x.email,
        departmentName: x.department?.name,
        userName: x.user?.username,
        allowCMS: x.allow_cms_access ? 'si' : 'no',
      }))
    } catch (error: any) {
      if (error.response && error)
        this.$buefy.toast.open({
          message: error.response.data.detail,
          type: 'is-danger',
        })
    }
    menuModule.setLoding(false)
  }

  showActiveDeleteModal(Employed: Employed) {
    this.objEdit = Employed
    this.delete_modal_active = !this.delete_modal_active
  }

  async deleteEmployeds() {
    try {
      await http.delete(`panel/employee/${this.objEdit.id}/`)
      this.data = this.data.filter((a: Employed) => a.id !== this.objEdit.id)
      this.getEmployeds()
      this.closeModal(false)
    } catch (error: any) {
      if (error.response && error)
        this.$buefy.toast.open({
          message: error.response.data.detail,
          type: 'is-danger',
        })
    }
  }
  closeModal(valor: boolean) {
    this.delete_modal_active = valor
  }

  closeForm() {
    this.action = 'list'
    this.getEmployeds()
  }
}
